import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import { getDrillingDuration, getProjectDataApi } from './api';

const { name } = options;

export const getDocumentationData = createAsyncThunk(
  `${name}/getDrillingOperationStatusListAll`,
  async (wellId: string, thunkAPI) => {
    try {
      return await getProjectDataApi(wellId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getDrillingDurationData = createAsyncThunk(
  `${name}/getDrillingOperationStatusListAll`,
  async (wellId: string, thunkAPI) => {
    try {
      return await getDrillingDuration(wellId);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
