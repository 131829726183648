import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useClassName } from 'utils/cn';
import { Location } from 'history';
import Auth from '../../contexts/auth';
import Slider from './forms/slider';
import SignIn from './forms/sign-in';
import './style.scss';

interface IHistoryState extends Location {
    state: { from?: string }
}

export default (): JSX.Element => {
  const { user } = useContext(Auth);
  const cn = useClassName('q');
  const location = useLocation() as IHistoryState;

  if (user) {
    if (location.state?.from) {
      return <Navigate to={location.state.from} />;
    }

    return <Navigate to="/" />;
  }

  return (
    <div className="auth-page grid">
      <section className={`auth-block sm:col-8 md:col-5 lg:col-4 xl:col-4
                col-offset-1 sm:col-offset-2 md:col-offset-1`}
      >
        <div className={cn('logo')} />
        <SignIn />
      </section>

      <section className={`auth-slider-section sm:col-12 md:col-12 lg:col-4 xl:col-4
                lg:col-offset-2 lx:col-offset-2 md-plus-hidden`}
      >
        <Slider />
      </section>

      <div className="bg-wrapper md-plus-hidden" />
    </div>
  );
};
